/* Please don't forget to put the fonts into the public folder */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/montserrat/Montserrat-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/montserrat/Montserrat-ThinItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/montserrat/Montserrat-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/montserrat/Montserrat-ExtraLightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/montserrat/Montserrat-Light.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/montserrat/Montserrat-LightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat/Montserrat-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/montserrat/Montserrat-Italic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat/Montserrat-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/montserrat/Montserrat-MediumItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/montserrat/Montserrat-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/montserrat/Montserrat-SemiBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat/Montserrat-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/montserrat/Montserrat-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/montserrat/Montserrat-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/montserrat/Montserrat-ExtraBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/montserrat/Montserrat-Black.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('./fonts/montserrat/Montserrat-BlackItalic.woff') format('woff');
}